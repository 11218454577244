import React, { useContext } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { FaArrowRight, FaArrowLeft } from "react-icons/fa";
import 'swiper/css';
import 'swiper/css/effect-coverflow';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

import { EffectCoverflow, Pagination, Navigation, Autoplay } from 'swiper/modules';
import '../../assets/css/slide_imageh.css';
import { GlobalDataContext } from '../../context/context';

const Block4 = ({image, image1, image2, image3, image4, image5, imgDefault}) => {
    const {rpdata} =useContext(GlobalDataContext);

    return (
        <>
      <div className="container">
      <h2 className="underline heading capitalize justify-center text-center mb-16">preview of our jumpers</h2>
      <Swiper
        effect={'coverflow'}
        grabCursor={true}
        centeredSlides={true}
        loop={true}
        slidesPerView={'auto'}
        coverflowEffect={{
          rotate: 0,
          stretch: 0,
          depth: 100,
          modifier: 2.5,
        }}
        pagination={{ el: '.swiper-pagination', clickable: true }}
        navigation={{
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
          clickable: true,
        }}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
      }}
        modules={[EffectCoverflow, Pagination, Navigation, Autoplay]}
        className="swiper_container"
      >
        {rpdata?.landings?.map((item) =>{
          return(
            <>
            <SwiperSlide>
          <img src={item.gallery[1]} alt="slide_image" />
          <p className='textcenter text-transparent bg-clip-text bg-gradient-to-r from-[#ff00bb] to-black text-center text-2xl font-bold'>{item.name}</p>
        </SwiperSlide>
            </>
          )
        })}
        

        <div className="slider-controler">
          <div className="swiper-button-prev slider-arrow">
            <FaArrowLeft className='text-white' name="arrow-back-outline" />
          </div>
          <div className="swiper-button-next slider-arrow">
            <FaArrowRight className='text-white' name="arrow-forward-outline" />
          </div>
          <div className="swiper-pagination"></div>
        </div>
      </Swiper>
    </div>
        </>
    )
}

export default Block4